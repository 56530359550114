
.layout-wrapper{
	height: 100%;
}

.layout-main{
	height: 100%;
}

.btn-row{
  .p-button{
    margin-left: 0.5em;
  }
  &.justify-row{
    justify-content: center;
    .p-button{
      margin-right:0.5em;
          flex-grow: 1;
    }
    &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }

  }
}

.p-col-auto{
    flex-basis: auto;
}
.layout-wrapper .layout-mask {
    z-index: 100005;

@media (max-width: 1024px){


   
    z-index: 100004;
    
}
}
.layout-sidebar {
   
    z-index: 100005;
    
}
.modal-pre-line{
  white-space: pre-line;
}