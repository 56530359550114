.pallet-dailyweight{
	margin:0;
	.p-button{
	    padding: 9px;
	    margin-top: 7px;

	}
}
.pallet-totalweight{
	font-size: 1.3em;
	display: flex;
	justify-content: flex-end;
	margin-top: 0.3em;

}

.pallet-id{
  font-weight: bold;
  margin-right: 2em;
}
.pallet-caja-prom{
  font-weight: bold;
  display: block;
  margin: 0 1em;
}