/* General */
$fontSize:14px;
$bodyBgColor:$gray-superlight; //#edf0f5;
$textColor:#333333;
$textSecondaryColor:#707070;
$borderRadius:3px;
$dividerColor:#e3e3e3;
$transitionDuration:.2s;
$maskBgColor:#424242;

/* Menu Common */
$menuitemBadgeBgColor:#007be5;
$menuitemBadgeColor:#ffffff;
$submenuFontSize:13px;
$menuitemActiveRouteColor:#1fa1fc;

/* Menu Light */
$menuBgColorFirst:#ffffff;//#EDEFF6;//#f3f4f9;
$menuBgColorLast:$gray-superlight;//#E2E5F0;//#d7dbe8;
$menuitemColor:#232428;
$menuitemHoverColor:#3AAD3A;//#0388e5;
$menuitemActiveColor:#3AAD3A;//#0388e5;
$menuitemActiveBgColor:#ffffff;
$menuitemBorderColor:rgba(207, 211, 224, 0.6);

/* Menu Dark */
$menuDarkBgColorFirst:#4d505b;
$menuDarkBgColorLast:#3b3e47;
$menuitemDarkColor:#ffffff;
$menuitemDarkHoverColor:lighten($main-green,15);
$menuitemDarkActiveColor:$main-green;
$menuitemDarkActiveBgColor:#2e3035;
$menuitemDarkBorderColor:rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarLeftBgColor:lighten($main-blue,7);//#007CBF;//#007AD9;//#0388E5;
$topbarRightBgColor:$main-blue;//#007CBF;//#007AD9;//#07BDF4;
$topbarItemBadgeBgColor:#ef6262;
$topbarItemBadgeColor:#ffffff;
$topbarItemColor:#ffffff;
$topbarItemHoverColor:#c3e8fb;
$topbarSearchInputBorderBottomColor:#ffffff;
$topbarSearchInputColor:#ffffff;

/* Footer */
$footerBgColor:#ffffff;