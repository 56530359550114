.filtros .search-field {
  display: flex;
  align-items: flex-end;

  svg {
    margin: 9px;
  }
}

.filtros {
  position: relative;
  z-index: 100002;

  .p-selectbutton {
    display: flex;

    .p-button {
      width: 100%;
    }
  }
}

.card.card-table-pag {
  box-shadow: none;
  background-color: transparent;

  table {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

  }
}
body .disable-selectAll .p-multiselect-header .p-checkbox {
  display:none;
}

body .p-datatable {
  tr:first-child th {
    border-top: 0;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tr td:first-child,
  tr th:first-child {
    border-left: 0;
  }

  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }

  tr td {
    overflow: hidden;
    text-overflow: ellipsis;

    &.cell-overflow {
      overflow: visible;
    }
  }

  .p-datatable-tbody {
    &>tr:nth-child(even) {
      background-color: white;
    }

    &>tr:nth-child(n):not(.p-datatable-row)>td {//Para el 'expander' con datos acumulados en reporte de pesos diarios de calidad
        padding: 2px 2px 3px 2px;
    }

    &>tr>td {

      //Estilo de columna que tiene totales
      &.col__total {
        background-color: $light-blue;
      }

      &.col__final {
        background-color: lighten($light-blue, 2);
      }

      &.col__label {
        background-color: lighten(#e8eaef, 3);
        //text-transform: capitalize;
        font-size: 0.9em;
      }

      &.p-editable-column {
        padding: 6px 12px;
        vertical-align: middle;
        height: 46px;

        &.p-cell-editing {
          //padding: 0.429em 0.857em;
        }

        .p-inputtext {
          height: 33px;
        }
      }

      border-color:lighten($gray, 15);
    }

  }

  .p-datatable-thead {
    text-transform: uppercase;
    font-size: 0.8em;

    &>tr>th {
      background-color: white;
      padding: 1.3em 1em;
      border-color: lighten($gray, 15);
      border-bottom-width: 2px;

      .p-column-title {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;

      }

      &.th-small {
        padding: 0.3em 1em;

      }

      &.p-sortable-column {
        color: $main-blue;
        padding-right: 30px;
        position: relative;

        &.p-highlight {
          color: $blue;

          background-color: $light-blue;

          .p-sortable-column-icon {
            color: $blue;

          }
        }

        &:hover {
          //background-color:$gray-superlight;
          background-color: inherit;
          color: $blue;

          .p-sortable-column-icon {
            color: $blue;

          }
        }

        .p-sortable-column-icon {
          color: $main-blue;
          font-size: 0.8em;
          margin: 5px;
          vertical-align: initial;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-100%);

        }
      }

      //color: $main-blue;
      //Estilo de columna que tiene totales
      &.col__total {
        background-color: $light-blue;
      }

      &.col__final {
        background-color: lighten($light-blue, 2);
      }

      &.col__label {
        background-color: lighten(#e8eaef, 2);

      }
    }
  }

  &.table-footer__total {
    .p-datatable-tfoot {
      tr td {
        text-align: left;
        background-color: $light-blue;
        border-top: 1px solid $blue;
        height: 46px
      }

    }
  }

  &.table-footer-no-border__total {
    .p-datatable-tfoot {
      tr td {
        border: none;
      }
    }
  }

  .p-datatable-tfoot {

    tr:last-child {
      border-bottom: 2px solid #c8c8c8;

      .text-align-right {
        text-align: right;
      }

      .text-align-left {
        text-align: left;
      }
    }

    tr:first-child {
      border-top: 2px solid #c8c8c8;
    }
  }
  .text-align-left-importante {
    text-align: left !important;
  }

  .p-paginator-bottom {
    border: none;
    background-color: transparent;
    margin-top: 1em;

    .p-paginator-element {
      border-radius: 8px;
      margin: 0 3px;

      &.p-highlight {
        background-color: $main-blue;
      }
    }
  }

  &.table-nofixed {
    table {
      table-layout: auto;

    }
  }

  //tabla conuna fila final de totales
  &.table-total-1 {
    table {
      tr:last-child {
        background-color: #f4f4f4;
        border-bottom: 2px solid #c8c8c8;
        border-top: 2px solid #c8c8c8;

        td {
          border: 1px solid #c8c8c8;
          font-weight: 700 !important;

          div,
          span {
            font-weight: 700 !important;
          }
        }
      }
    }
  }


  //tabla conuna fila final de totales
  &.table-total-2 {
    table {

      tr:last-child,
      tr:nth-last-child(2) {
        background-color: #f4f4f4;

        td {
          border: 1px solid #c8c8c8;
          font-weight: 700 !important;

          div,
          span {
            font-weight: 700 !important;
          }
        }
      }

      tr:last-child {
        border-bottom: 2px solid #c8c8c8;
      }

      tr:nth-last-child(2) {
        border-top: 2px solid #c8c8c8;
      }
    }
  }


  //tabla conuna fila final de totales
  &.table-total-3 {
    table {

      tr:last-child,
      tr:nth-last-child(2),
      tr:nth-last-child(3) {


        background-color: #f4f4f4;

        td {
          border: 1px solid #c8c8c8;
          font-weight: 700 !important;

          div,
          span {
            font-weight: 700 !important;
          }
        }
      }

      tr:last-child {
        border-bottom: 2px solid #c8c8c8;
      }

      tr:nth-last-child(3) {
        border-top: 2px solid #c8c8c8;
      }
    }
  }




  //Estilo de columna que tiene detallls
  .col__label {
    font-weight: 600;

    //text-align: center;
  }

  .col__total {
    //text-align: center;
  }
}


.col-size-1 {
  width: 100px;
}

.label-unidad {
  //text-transform: uppercase;
  color: $gray-dark;
  font-size: 0.7em;
  margin-left: 0.2em;

  &.left {
    margin-right: 0.2em;
    margin-left: 0;
  }
}

.inner-data-line {
  display: block;
}

.table-title {
  font-size: 1.2em;
}

.p-datatable-scrollable {
  .p-datatable-scrollable-body {
    height: calc(100vh - 305px);
  }


  &.tabla-calidad-pesos {
    .p-datatable-scrollable-body {
      height: min-content;
      max-height: 750px;
    }
  }

  &.tabla-empaque-reporte {
    .p-datatable-scrollable-body {
      height: calc(100vh - 440px);
    }
  }

  &.tabla-empaque-reporte-detalle,
  &.tabla-presentaciones {
    .p-datatable-scrollable-body {
      height: calc(100vh - 280px);
    }
  }

  &.tabla-actividades {
    .p-datatable-scrollable-body {
      height: calc(100vh - 205px);
    }
  }
}

.tabla-actividades,
.tabla-presentaciones {
  tr {
    td:first-child {
      //color:$blue;
    }
  }

  tbody tr:hover {

    td {

      &:first-child {
        //color:$blue-dark;
      }
    }



  }
}

table {
  .icon-mini {
    margin: 2px auto;
  }

  .icon-mini.icon-click {

    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      margin: 0px auto;
      font-size: 0.805em;
      width: 34px;
      height: 34px;
    }
  }

  .proveedor-block {
    vertical-align: middle;

    button,
    .proovedor-info {
      display: inline-block !important;
      vertical-align: middle;
    }

    button.p-button.invisible-button {
      margin-right: 5px !important;
    }
  }

  .proveedor-name {
    margin-top: 8px;

    margin-bottom: 0;
    font-weight: 600;
  }

  .proveedor-product {
    margin-top: 0;

    font-weight: 400;
    font-size: 0.7em;
  }
}

.p-col-wider {
  max-width: 25px;
}

body .header-hover .p-datatable-thead > tr > th:hover {
    color:#45abec;
    cursor: pointer;
}

body .header-hover .p-datatable-thead > tr > th:not(:hover) {
  color:#0056a7;
}



//Calidad - reporte general de pesos diarios
.rgps-celda-header{
  height: 68px !important;
  width: 188px !important;
}
.rgps-celda-reporte{
  width: 188px;
  height: 93px;
  padding: 0px 10px 6px 10px !important;
  text-align: center;
  .muestra{
    text-align: left;
  }
}

.rgps-celda-reporte-large{
  width: 188px !important;
  height: 122px !important;
  padding: 0px 0px 0px 0px !important;
}

.rgps-celda-header-pres{
  width: 142px !important;
  height: 68px !important;
  
}
.rgps-celda-reporte-pres{
  width: 142px !important;
  height: 93px !important;
  max-height: 93px !important;
}

//Calidad - reporte de pesos diarios
.reporte-pesos-diarios-contenedor{
  display: flex;
  width: 100%;
  flex-direction:row;
  text-overflow: ellipsis;
}

.invoice-expander-col-width{
  width: 120px !important;
}
.rpd-expander-col-width{
  width: 50px !important;
}
.rpd-nombre-col-width{
  width: 180px !important;
}
.rpd-pres-col-width{
  width: 142px !important;
}
.rpd-acc-col-width{
  width: 240px !important;
}
.rpd-acc-double-col-width{
  width: 580px !important;
}

.rpd-celda-datos{
  padding: 1px 1px 8px 1px !important;
  width: 210px !important;
  height: 93px !important;
}
.rpd-celda-header{
  width: 210px !important;
  height: 64px;
}
.rpd-celda-acc-datos{
  padding: 1px 1px 8px 1px !important;
  width: 280px !important;
  height: 93px !important;
}

.styles-tabla-expander { 
  width: 270px; 
  padding: 0px 0px 0px 0px;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis ;
  //background-color: pink;padding: 4px; //Para pruebas de implementación
}
.styles-tabla-pres-general { 
  width: 142px !important; 
  padding: 0px 0px 0px 0px;
  height: fit-content;
}
.styles-tabla-pres-fijos { 
  width: 182px; 
  padding: 0px 0px 0px 0px;
  text-overflow: ellipsis ;
}
.tabla-expander-empty { 
  width: 100% !important;
  overflow: hidden;
}
.styles-tabla-reporte-pesos { 
  width: 100%;
  padding: 0px 0px 0px 0px;
  overflow: scroll;
  text-overflow: ellipsis ;
  //background-color: green;padding: 4px; //Para pruebas de implementación
}
.calidad-tooltip-pesos { 
  background-color: #45abec;
  position: absolute !important;
  width: 122px !important;
  height: 62px !important;
  margin-top: -12px !important;
  opacity: 0 !important;
}

.body-acciones-icono{
  max-height: 48px;
  width: fit-content;
  max-width: 70px !important;
  padding: 4px;
}
.body-acciones-col{
  height: 100% !important;
  display: flex;
  justify-content: center;
  width: 80px !important;
  border-left: 0px !important;
  border-bottom: 0px !important;

}
.body-acciones-col-header{
  width: 80px !important;
  border-bottom: 1px solid #e3e7e7 !important;
}

.footer-no-border{
  border-left: none !important;
  border-right: none !important;
}

.col-grupo-dev{
  padding: 3px !important;
  font-size: 13px;
  width: 150px;
}
.col-pres-dev{
  padding: 1px !important;
  font-size: 13px;
  width: 252px;
}
.col-invs-dev{
  padding: 5px !important;
  font-size: 13px;
  width: 105px;
}
.cont-tabla-clientes-2022{
  min-height: 340px;
}