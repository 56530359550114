
.form-group{
	//margin: 0.5em 0;
	& > label{
		display: block;
		width: 100%;
		margin-bottom: 0.3em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	}
	.p-dropdown ,.p-inputtext, .p-multiselect{
		display: block;
		width: 100%;

	}
  .p-multiselect .p-multiselect-label-container{
    display: block;
    width: 100%;
    max-height: 33px;
    overflow: auto;
  }
  
}


button.p-button{
	margin: 0.5em 0;
  background: $main-blue;
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 0.4em 1.2em;
  font-weight: 600;
  &.btn-line{
    background:transparent;  
    color: $main-blue;
  }
}

.reactbtn-icon{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  svg{
    font-size: 1.4em;
  }
}
.p-button-block{
  display: block;
  width: 100%;
}

.btn-block{
	display: block;
	width: 100%;
}

.select-2{
  .p-buttonset.p-buttonset-3{
    .p-button{
      width: 50%;
    }
  }
}
.select-4{
  .p-buttonset.p-buttonset-3{
    .p-button{
      width: 25%;
    }
  }
}
.switch-container{
  display: flex;
  align-items: center;
  span{
    padding:0.5em;
    
  }
}
.form-output{
    margin: 0;
    min-height: 33px;
    font-weight: 600;
    display: flex;
    align-items:center;
}

.p-calendar{
  width: 100%;
}

.p-datepicker{
  z-index:100004 !important;  
}


.p-picklist{
  width: 100%;
   .p-picklist-list{
    width: auto;
   }
   .p-picklist-buttons{
    width: 70px;
   }
}

.p-selectbutton{
  white-space: nowrap;
}


.p-multiselect-items-wrapper, .p-multiselect-panel{
  z-index: 100003;
  
}
.p-togglebutton{
  display: block;
}
.cont-button-distr-trash{
  align-self: flex-end;
  padding: 0!important;
  margin: 0!important;
  margin-bottom: .6em!important;
}
.p-button-distr-trash{
  background-color: $main-blue;
  border: none;
  height: 32px !important;
  width: 100% !important;
  margin: 0 0 0 0  !important;
  padding: 0 0 0 0 !important;
  color: white;
  //font-size: 1.5em;
}

.unidad-cajas-distribucion{
  text-overflow:clip !important;
  vertical-align: middle !important;
  text-align: center !important;
  align-self: center !important;
  overflow: inherit !important;
}
.position-sticky{
  position:sticky !important;
}
.position-initial{
  position:inherit !important;
}