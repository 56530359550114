$altura-topbar:50px;
$padding-main-container:32px;
$altura-titulos:74px;
$altura-filtros:78px;

.chart-page {
  display: flex;
  flex-direction: column;
  height: 80vh;
  height: calc(100vh - 70px);
  height: 100%;
  flex-wrap: nowrap;

  .title-container {
    flex-grow: 0;
  }

  .p-col-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .p-col-12.chart-container {
    //flex: 1 1 auto;
    height: calc(100vh - #{$altura-topbar} - #{$padding-main-container} - #{$altura-titulos});
  }

  .p-col-12.chart-container-filters {
    height: calc(100vh - #{$altura-topbar} - #{$padding-main-container} - #{$altura-titulos} - #{$altura-filtros});
    //z-index:100002;
  }

  .p-col-12.chart-container-twofilters {
    height: calc(100vh - #{$altura-topbar} - #{$padding-main-container} - #{$altura-titulos} - #{$altura-filtros} - #{$altura-filtros});
    //z-index:100002;
  }
  .p-col-12.chart-container-large {
    height: 1400px;
    //z-index:100002;
  }

  .p-col-12.chart-container-filters.cosecha-chart {
    height: calc(70vh - #{$altura-topbar} - #{$padding-main-container} - #{$altura-titulos} - #{$altura-filtros});
    //z-index:100002;
    min-height: 350px;
  }

  .p-col-12.chart-container,
    {
    //flex: 1 1 auto;
    height: calc(100vh - #{$altura-topbar} - #{$padding-main-container} - #{$altura-titulos});
  }

  .p-col-12.chart-container-main,
  .chart-container-secondary {

    &>div {
      padding: 10px;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 10px;

      &:hover {
        border: 1px solid $blue-dark;
        background: white;

        .apexcharts-title-text {
          fill: $blue-dark;
        }
      }
    }

  }

  .p-col-12.chart-container-main {
    height: calc(100vh - #{$altura-topbar} - 40px - 200px - #{$padding-main-container} - #{$altura-titulos} - #{$altura-filtros});
    min-height: 200px;
    margin-bottom: 1em;

    .apexcharts-text tspan {

      font-size: 9px;
    }
  }

  .chart-container-secondary {
    height: calc(30vh - #{$altura-topbar} - 14px - #{$padding-main-container} - #{$altura-titulos} - #{$altura-filtros});
    margin-right: 0;
    margin-left: 0;

    &>div {
      padding: 10px 10px 0 10px;
    }

    .apexcharts-text tspan {

      font-size: 7px;
    }
  }
}

.apexcharts-legend-marker {
  //width: 22px !important;
  //height: 22px !important;
}

.apexcharts-tooltip.apexcharts-theme-dark,
.apexcharts-tooltip.apexcharts-theme-light {
  z-index: 100002;

}

.dash-type {

  /*
    width: 20px;
    height: 22px;
    position: absolute;
    right: 100%;
    border: 2px solid white;
    //border-radius: 50%;
    top: 1px;
    margin-right: 3px;
    overflow: hidden;
    //overflow: hidden;
    &:before{
      //display: block;
      //content: "";
      width: 14px;
      border-radius: 50%;
      height: 14px;
      background: white;
      left:0;
      right: 0;
    }
    */

}


.titulo-seccion-graficas {
  display: block;
  position: relative;
  text-align: left;
  width: 100%;
  font-size: 1.5em;
  padding-left: 50px;
  padding-bottom: 5px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-bottom: 2px solid #A6A6A6;

  .icon-general {
    width: 40px;
    height: 40px;
    position: absolute;
    left: -1px;
    top: 2px;
    background-color: #f4f6fa;
    background-size: 60%;

  }
}