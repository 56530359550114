

.embarque-dates-block{
  div{
    margin-right: 0;
  } 
  label{
    margin-left: 0;
    white-space: nowrap;
  }
}


.no-grow{
  flex-grow: 0;
}

.embarque-details-card{
	margin-bottom: 0.5rem;
      padding: 0.3em 1em;
}



.acciones{
  
  display: flex;
  input{
    width: 100%;
  }
  button{
    margin: 0 0 0 0.5em;
  }
}

.embarque-details, .lista-archivos{
  //margin-top: 1em;
  margin:0;
  padding:0;
  .p-col-auto{
    display: flex;
    
    align-items: center;
  }
  .p-col div{
    font-size: 1em;
    margin-bottom: -0.4em;
  }
  label{
    text-transform:uppercase;
    font-size: 0.6em;
  }
}

.chat-card{
  padding-top:1em;
  padding-bottom:1em;
}
.card-mod{
  border:1px solid $main-blue;
  position:relative;
  &:after{
    content: "Exclusivo HTG";
    background-color: $main-blue;
    color: white;
    padding:0.5em  0.8em;
    font-size: 0.7em;
    position: absolute;
    bottom:-0.5em;
    right: -0.5em;
  }
}

.embarque-status{
  display: flex;
    flex-wrap: nowrap;
    align-items: center;
  font-size:1em;
  text-align: left;
  span{
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color:black;
    border-radius:50%;
     vertical-align: middle;
     margin-right: 0.3em;
     border-width:1px;
     border-style:solid;
     border-color:black;
  }
}
.iniciado{
  span{
    background-color:white;
    border-color:darken($gray, 10);
  }
}
.en-proceso{
  span{
    background-color:$yellow;
    border-color:darken($yellow, 10);
  }
}
.despachado{
  span{
    background-color:$green;
    border-color:darken($green, 10);
  }
}
.en-cruce{
  span{
    background-color:$green-dark;
    border-color:darken($green-dark, 10);
  }
}
.finalizado{
  span{
    background-color:$blue;
    border-color:darken($blue, 10);
  }
}
.atrasado{
  span{
    background-color:$red !important;
    border-color:darken($red, 10) !important;
  }
}
.fecha-filtro{
  display: flex;
  button.p-button{
    margin: 0;
    padding:0.2em 0;
  }
}
.late-status{
  font-size:0.7em;
  font-weight: bold;
  text-transform: uppercase;
}
.fecha-atrasada{
  color: red;

}


.embarque-card {
  .card-id{
    background-color:white !important;
    overflow: hidden;
    img{
      width: 90%;
      margin:5%;
      border-radius:4px;
    }
  }
}
.bor-mastronardi{
  .card-id{
    border-color:$orange;  
    background-color:$orange !important;
  }
  border-color:$orange;
}
.bor-windset-farms{
  .card-id{
    border-color:$red-dark;  
    background-color:$red-dark !important;  
  }
  border-color:$red-dark;

  
}
.bor-nature-fresh-farms{
  .card-id{
    border-color:$green-dark;  
    background-color:white !important;
  }
  border-color:$green-dark;
}
.bor-otros{
  .card-id{
    background-image:url('../images/icons/truck-icon.svg') !important;
    background-color:white;
    background-repeat:no-repeat;
    img{
      display: none;
    }
  }
}

.embarque-mastronardi,
.embarque-windset-farms,
.embarque-nature-fresh-farms{
  &:before{
    content: "";
    width: 20px;
    height: 20px;
    border-radius:2px; 
    margin-right:7px;
    vertical-align: middle;
    display: inline-block;
    background-position:center;
    background-size:contain;
    background-repeat:no-repeat;
  }
}
.embarque-mastronardi{
  &:before{
   
    background-image: url('../images/clientes/sunset.png');
    border:1px solid $orange;  
    background-color:$orange ;
  
  }
  color: darken($orange-dark, 5);
}
.embarque-windset-farms{
  &:before{
    
    background-image: url('../images/clientes/windset.png');
    border:1px solid $red-dark;  
    background-color:$red-dark ;  
    

  }
  color: darken( $red-dark, 5);

  

}
.embarque-nature-fresh-farms{
  &:before{
    
    background-image: url('../images/clientes/naturefresh.png');
    border:1px solid $green-dark;  
    background-color:white ;
    background-size: 80%;
   
  }
  color: darken( $green-dark, 5);
}

.embarque-mastronardi{
}
.embarque-windset-farms{
}
.embarque-nature-fresh-farms{
}
.embarque-otros{
}

.lista-archivos{
   
}

.lista-mensajes{
  padding: 0;
  margin: 0;
  li{
    display: flex;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom:1px solid #e8e8e8;
    
    &:last-child{
      border-bottom:0;
      
    }

  }
  min-height: 100px;
  max-height: calc(100vh - 614px);
    overflow: auto;
  margin-top: 10px;
} 
.mensaje-sender{
  display: block;
  width: 20%;
  margin-right: 1em;
  text-align: right;
  min-width: 100px;
}
.mensaje-nombre{
  //font-size: 1.1em;
  
  font-size: 0.8em;
  display: block;
}

.mensaje-fecha{
  font-size: 0.6em;
  color:gray;
  display: block;
}

.mensaje-msj{
  //font-size: 0.8em;
  display: block;
}

.usuario-interno{
  .mensaje-nombre{
   
    color:$blue-dark;
   
   
  }

}
.usuario-externo{
  .mensaje-nombre{
   
    color:$red-dark;
   
   
  }

}
.archivos-card-wrapper{
  overflow: hidden;
}
.archivos-card-content{
  max-height: 590px;
  overflow-y: scroll
}