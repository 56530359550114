.layout-logo{
  padding:2em 3.3em 1em 3.3em;
  img{width: 95%;}
}
.sisu-credit{
  padding:1.2em 1em 1em 1em;
  text-align: center;
  span{
    display: block;
    font-size: 0.5em;
    color: $gray-dark;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
}
.sisu-logo{
  width: 70px;
}
.sisu-logo-top-text{
  font-size: 14px;
}
.sisu-logo-bottom{
  width: 116px;
}
.sisu-logo-bottom-text{
  font-size: 12px;
}
.layout-profile{
  .username{
    margin:0;
  }
  .job{
    margin:0;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
  }
}

.layout-menu-container{
  padding-bottom:0em;
	.svg-inline--fa{
		width: 1.5em;
	}
}

.layout-topbar{
  display: flex;
  z-index: 100003;
  align-items: center;
  //justify-content: space-between;
  padding: 0  1em;
  &:after{
    content: none;
  }
  .layout-topbar-title, .layout-menu-button{
    width: 33%;
  }
  .layout-topbar-title{
    text-align: center;
  }
  .p-dropdown, .pdropdown:hover{
    background-color: transparent;
    color: white;
    border-color: white !important;
    margin-left: auto;
    .p-dropdown-label, .p-dropdown-trigger,
    .p-dropdown-label:hover, .p-dropdown-trigger:hover{
      
        border-color: white;
        background-color: transparent;
        color: white;
      
      
    }
  }
}

.layout-menu{
  & > li ul{
    padding-right: 1.5em;
  }
  li.sub-item{
    text-transform: uppercase;
    span{
        font-size: 0.9em ;
    }
    a{
      padding-top:0 ;
    }
  }
  li.final-item{
    border-bottom:1px solid rgba(207, 211, 224, 0.6)
  }
}  

