.week-day{
  font-size: 1em;
  font-weight: 600;
  text-transform:uppercase;
}
.order-card{
  margin: 0.5em 0;
  margin-top: 26px;
  cursor: pointer;
  border-radius:5px;
  .p-col-auto{

    padding: 0.5em;
    padding-right:0;
  }
  .svg-inline--fa{
    width: 14px;
    display: block;
    font-size: 0.8em;
    margin-top: 0.1em;
  }
}
.order-number{
    font-size: 1.1em;

    font-weight:600;
    margin-bottom: -0.2em;
}
.cliente-image {
      width: 45px;
      height: 45px;
      
      vertical-align: middle;
      margin-top: -20px;
      margin-right: 6px;
      border-radius: 14px;
      border: 1px solid white;

    
    }
.order-client{
  //margin-bottom: 1em;
  //text-transform: uppercase;
  font-size: 0.7em;
  margin: 0.2em 0;
}
.order-border{
  font-size: 0.7em;
  
  color:white;
  margin: -14px -14px 10px -14px;
  
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  font-weight:600;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .order-bordercolor{
    padding: 0.3em 0.7em;
    flex-grow: 0;
    float: right;
  }
  &.laredo .order-bordercolor{
    background:$blue;  
    color: white;
    display: inline-block;
  }
  &.nogales .order-bordercolor{
    background:$purple;
    color: white;
    display: inline-block;
  }
  &.mcallen .order-bordercolor{
    background:$yellow;
    color: black;
    display: inline-block;
  }
  &.na .order-bordercolor{
    display: none;
  }
  .order-destinycolor{
    padding: 0.3em 0.7em;

    font-size: 1.1em;
    
  }
  .order-destinyname{
    padding:4px 8px 7px 8px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  &.mastronardi{
    background: lighten($orange,20);
    color: black;
     &.mastronardi .cliente-image{
      background: $orange;
      
      
      
    }
    
  }
  &.windset-farms{
    background: lighten($red-dark,50);
    color: black;
    
  }
  
  &.nature-fresh-farms {
    background: lighten($green-dark,45);
    color: black;
    & .cliente-image{
      background: white;
      padding:5px;
    }
  }
  &.bc-hot-house-foods-inc,
  &.del-fresco, 
  &.kinova-fresh-llc, 
  &.nature-sweet, 
  &.pure-flavor-, 
  &.bionatur,
  &.agros-sa-de-cv{
    background: lighten($gray,10);
    color: black;
    & .cliente-image{
      background: white;
    }
  }
}
.order-destiny{
  font-size: 0.7em;
  font-weight:600;
}
.order-consolid{
  font-size: 0.7em;
text-transform: uppercase;
}
.order-amounts{
  text-align: center;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  margin-top: 0.5em;
 margin-bottom: 0.5em;
  padding-bottom:0.2em;
}
.order-pallets{

}
.order-boxes{

}
.order-sub-title{

}

.order-data-number{
  font-size:1.35em;
  margin-bottom: -0.2em;
}
.order-label{
  //text-transform: uppercase;
  font-size: 0.7em;
}


.cliente-image {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
.cliente-image--dropdown{
  display: inline-block;
  vertical-align: middle;
  margin-top: 0 !important;
}
.cliente-name--dropdown{
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5em;
  vertical-align:auto;
}
.order-cliente-section{
  flex-wrap: nowrap;
  margin-top: 0.1em;
  margin-bottom: 0.5em;
}

body .p-panel.order-detail{
  .p-panel-titlebar{
    padding:0;
    background: transparent;
    border:none;
    font-weight: 400;
  }
  .p-panel-titlebar-toggler{
        font-size: 10px;
    padding: 4px;
    width: auto;
    height: auto;
    line-height: initial;
  }
  .p-panel-content{
    border:none;
    padding:0;
    margin-top: 0.8em;
  }
}

.order-item{
  border: 2px solid lighten($gray,10);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding:0.5em;
  font-size: 0.7em;
  margin-bottom: 0.5em;
  border-radius: 4px;
  .icon-mini{
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  div{
     text-overflow: ellipsis;
          overflow: hidden;
            display: block;
            white-space: nowrap;
  }
}

.orden-items-table{
  width: 100%;
  margin-bottom: 1em;
  table{
    table-layout: initial !important;
  }

}
.table-btns{

}
.table-btn{
  -webkit-appearance: none;
  border: 1px solid $blue-dark;
  color: $blue-dark;
  border-radius:50%;
  width: 28px;
  height: 28px;
  margin: 0 2px;
  display: inline-block;
  cursor: pointer;
}
.order-details{
  //margin-top: 1em;
  .svg-inline--fa{
    margin-right: 0.5em;
  }
}
.order-details, .lista-archivos{
  //margin-top: 1em;
  
  .p-col-auto{
    display: flex;
    
    align-items: center;
  }
  .p-col div{
    font-size: 1.1em;
    margin-bottom: -0.4em;
  }
  label{
    text-transform:uppercase;
    font-size: 0.7em;
  }
}
.order-po{
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size:1.1em;
}

.tabla-cumplimiento{
  margin-top:0.5em;
  border-width: 2px;
  border-radius:5px;
  overflow: hidden;
  border-style:solid;
  margin-bottom: 1em;
  table{
  //table-layout: unset;
    td{
      //white-space: nowrap;
    }
  }
  //padding:1em;
  
  .packout-warning{

  }
}

.chart-btn{
        border:1px solid $blue-dark;
        color: $blue-dark; 
        display: inline-block;
        padding:5px 8px;
        border-radius:4px;
        background:white;
        &:hover{
          
          border:1px solid $blue;
          color: $blue; 
          //transform: scale(1.1);
        }
}

.p-button.btn-cumplimento, .p-button.btn-line{
          padding: 0;
          margin: 0;
          margin-bottom: 0.5em;
          margin-top:-0.5em;
          font-size: 0.5em;
          .p-button-text {

          padding: 0.4em 0.5em !important;
        }
        background:transparent;
        border:1px solid $blue;
        color: $blue; 
        &:enabled:hover{
          background:transparent;
          border:1px solid $blue;
          color: $blue; 
        }
        &.packout-warning{

          border:1px solid $red;
          color: $red; 
          &:enabled:hover{
            
            border:1px solid $red;
            color: $red; 
          }
        }
        &.btn-help{
          padding:0em 0.35em;
          margin:0;
          border-radius:50%;
          vertical-align: middle;
          width: auto;
        }
        &.btn-help2{
          display: block;
          width: 60%;
          height: 40%;
          position: absolute;
          bottom:30%;
          right:20%;
          opacity: 0;
        }
  }
.orden-ok{
  color: $green-dark;
  span{

  }
}
.orden-fail{
  color: $red;
}
.bajo-minimo{
  color:$red;

}
.sobre-maximo{
  color:$blue;
}
.cumplimiento-result{
  .fa-caret-down{
    color: red;
    & + span {
      color: red;
      //font-weight: bold;
    }
  }
  .fa-caret-up{
    color: $green-dark;
    & + span {
      color: $green-dark;
    }
  }
}
.cumplimiento-result-unico{
  .fa-caret-up{
    color: $blue;
    & + span {
      color: $blue;
    }
  }
}
.arrow-alert{
  color: red;
  .fa-caret-down, .fa-caret-up{
    color: red;
    
  }
}
.p-tooltip {
    max-width: none;
}
.apexcharts-tooltip{
  padding: 10px 10px 0 10px;
  .chart-label{
    .p-tooltip-text{
      border-bottom: 1px solid rgb(200,200,200);
      margin-bottom: 10px;
      &:last-child{
        border-bottom: 0px;
        margin-bottom: 0px;
      }
    }
  }
}


.p-tooltip-text{
  max-height: 100%;
  overflow: auto;
    white-space: initial !important;
  div{

  }
  p{
    margin:0;
  }
  &:after{
    content: "";
    clear: both;
  }
  .tooltip-datas{
    display: flex;
    justify-content: center;
    vertical-align: center;
    width: 100%;
  }
  .tooltip-title{
    margin:0;  
    font-weight: bold;
  }
  .tooltip-def{
    margin:0;  
        font-size: 0.8em;
  }

  .tooltip-date{
    margin:0;  
    font-size: 0.8em;
    white-space: nowrap;
  }
  .tooltip-label{
    margin:0;
    font-size:0.7em;
    text-transform: uppercase;
  }
  .tooltip-data{
    margin:0;
    padding:10px;
    font-size:1em;
    text-align: center;
  }
  
}
  .tooltip-separator{
    
    border-bottom:1px solid white;
    &:last-child{
      border:0;
    }
  }

/*
$pallet-size:14px;
$pallet-margin:2px;
$ancho:15;
$alto:2;
$padding-camion:10px;
.camion{
  background-image:url('../images/camion.jpg');  
  border:1px solid black;
  //background:white;
  padding:$padding-camion 105px $padding-camion $padding-camion;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: ($pallet-size * $alto) + (2px )  + ($pallet-margin * $alto * 2)  + ($padding-camion*2);
  width: ($pallet-size * $ancho) + (2px * $ancho) + ($pallet-margin * $ancho * 2)  + ($padding-camion*2);
  justify-content: flex-start;
  align-items:flex-start;
  background-size:contain;
}
.camion-pallet{
  width: $pallet-size;
  height: $pallet-size;
  margin: $pallet-margin;
  border:1px solid black;

}
*/
.flex-nowrap{
  flex-wrap: nowrap;
}

.camiones-wrapper{
  padding: 0.5em;
}

.camion{
    background-image:url('../images/camion.png');  
    //border: 1px solid black;
    padding: 10px 105px 10px 10px;
    display: flex;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column-reverse ;
    height: 58px;
    width: 307px;
    justify-content: flex-start;
    align-items: flex-start;
    background-size: contain;
}
.camion-pallet{
    width: 11.4px;
    height: 14px;
    margin: 2px 1px;
    border: 1px solid $gray;
    //background:transparent;

}



@mixin pallets() {
  @for $i from 1 through 30 {
    div[class*="pallet-#{$i}"] {
      @for $b from 1 through $i {
        .camion-pallet:nth-child(#{31 - $b}){
          //background:$blue;
        }
      }
    }
  }
}
@include pallets();


.cumplimiento{
  .icon-general{
    display: inline-flex;
    
    font-size: 1.1em;
    width: 38px;
    height: 38px;
    vertical-align: middle;
  }
}
.sobrante-semana{
  margin-left:1em;
  display: inline-block;
  vertical-align: middle;
}
.sobrante-data{
  font-size: 1.4em;
  margin-bottom: 0;
}

.sobrante-label{
  display: block;
  text-transform: uppercase;
  font-size: 0.5em;
  margin:0;
}


.cumplimiento-card{
  .card-header{
    left: 0;
  }
  .p-card-body {
    padding: 16px 14px 10px 14px;
  }
}
  .p-card-body-ordenes {
    //margin: 5px 5px 5px 5px;
    min-width: 32%;
  }
  .btn-consolidar{
    margin: 0.5em 0 !important;
    text-transform: uppercase !important;
    font-size: 0.8em !important;
    padding: 0.5em !important;
    font-weight: 600 !important;
    min-width: 49% !important;
    min-height: 36px;
    &.btn-line{
      background:transparent !important;  
      color: $main-blue !important;
    }
  }
  .btn-camion{
    min-height: 36px;
    margin: 0.5em 0 !important;
    //width: fit-content;
    max-width: 100%;
    min-width: 49% !important;
    font-size: 0.72em !important;
  }

  .btn-row-man{
    .btn-row{
      padding: .5em !important;
      justify-content: space-between !important;
    }
  }

  @media (min-width: 1510px) {
    .btn-manifiesto {
      padding: 2px 2px 2px 2px !important;
      font-size: 11px !important;
    }
  }
  @media (max-width: 1510px) {
    .p-card-body-ordenes {
      //margin: 5px 5px 5px 5px;
      min-width: 48% !important;
    }
  }
  @media (max-width: 1070px) {
    .p-card-body-ordenes {
      //margin: 5px 5px 5px 5px;
      min-width: 100% !important;
    }
  }
  @media (max-width: 1020px) {

  }
  @media (max-width: 720px) {
    .btn-consolidar {
      min-height: 44px !important;
      margin: 0px !important;
      margin-top: 8px !important;
      margin-bottom: 8px !important;
      min-width: 100% !important;
      max-width: 100% !important;
    }

    .btn-camion{
      min-height: 44px !important;
      margin: 0px !important;
      margin-top: 8px !important;
      margin-bottom: 8px !important;
      min-width: 100% !important;
      max-width: 100% !important;
    }

  .btn-row-man{
    .btn-row{
      padding-left: .5em !important;
      padding-right: .5em !important;
      font-size: 16px;
    }
  }
  }
img.foto-modal{    
  max-height: 80vh !important;
}


.btn-img{
  margin: 0.5em  !important;
}



.matriz-comparativa{
  position:relative;
  display: flex;
  flex-direction: row-reverse;
  align-items:center;
  justify-content: space-between;
      flex-wrap: wrap;
   &:before{
      content: "" ;
      background:$gray-superlight;
      background: lighten(#e8eaef,2);
      display: block;
      width: 300%;
      height: 100%;
      padding: 4px;
      position: absolute;
      clip-path: polygon(0 0, 0 100%, 100% 100%);
      margin:-0.429em -0.857em;
      margin-left: -120%;
      left:0%;
      padding:0.429em 0.857em;
    }
  .matriz-dato{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    //position: absolute;
    
    
    &:nth-child(1){
      text-align: right;
      //background:white;
      margin-bottom: 1em;
    }

  }
}

.opacity-0{
  border: none;
  background-color: transparent;
}

.columna-redistribuir{
  padding: 4px !important; 
}

.boton-redistribuir{
  width: 100% !important; 
  padding: 4px  !important; 
}
.boton-redistribuido{
  width: 100% !important; 
  padding: 4px  !important; 
  background-color: green  !important; 
}
.boton-redistribuido-editado{
  width: 100% !important; 
  padding: 4px  !important; 
  background-color: brown  !important; 
}
.boton-redistribuido-no-editado{
  width: 100% !important; 
  padding: 4px  !important; 
  background-color: darkgoldenrod  !important; 
}
.producto-text-small{
  font-size: 10px;
}
.boton-redistribuir-med{
  width: 100%;
  padding: 1px !important; 
}
.boton-redistribuido-med{
  width: 100%;
  padding: 1px  !important; 
  background-color: green  !important; 
}
.p-button.c-black{
  color:black !important;
}
.padding-card-manifiestos{
  padding: .5em .5em .5em .5em !important;
}

.complemento-total-text{
  font-size: 14px;
  font-weight: 700;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}