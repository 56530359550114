a {
  cursor: pointer;
}

.mh-100 {
  min-height: 100%;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.d-block {
  display: block;
}

.p-flex {
  display: flex;
}
.p-justify-content-center {
  justify-content: center;
}
.p-align-items-center {
  align-items: center;
}

.p-flex-direction-column {
  flex-direction: column;
}

.p-0 {
  padding: 0;
}
.p-05 {
  padding: .5em;
}
.p-hor-05 {
  padding-left: .5em;
  padding-right: .5em;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 1em;
}
.pt-4 {
  padding-top: 4em;
}
.pa-4 {
  padding: 4em,4em,4em,4em;
}

.pb-0 {

  padding-bottom: 0;
}

.pl-1 {
  padding-left: 0.5em;
}

.pr-1 {
  padding-right: 0.5em;
}

.pl-2 {
  padding-left: 1em;
}

.pr-2 {
  padding-right: 1em;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0;
}
.mt-05 {
  margin-top: .5em;
}
.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mb-0 {

  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.ml-01 {
  margin-left: 0.1em !important;
}
.ml-02 {
  margin-left: 0.2em !important;
}
.ml-05 {
  margin-left: 0.5em !important;
}

.ml-1 {
  margin-left: 1em !important;
}

.mr-05 {
  margin-right: 0.5em !important;
}
.mr-1 {
  margin-right: 1em !important;
}
.mr-2 {
  margin-right: 2em !important;
}

.mb-0 {
  margin-right: 0;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-4 {
  margin-bottom: 4em;
}
.pl-0{
  padding-left: 0;
}
.pr-0{
  padding-right: 0;
}

.max-6 {
  max-height: 8em;
  overflow: auto;
}

.text-center {
  text-align: center !important;
}

.btn-row {
  display: flex;
  justify-content: flex-end;
}

.text-capital {
  text-transform: capitalize;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
  width: 100%;
}

.modal-35 {
  min-width: 35vw;
}

.modal-50 {
  min-width: 50vw;
}


.modal-75 {
  min-width: 75vw;
}

.layout-topbar {
  .p-message {
    position: fixed;
    bottom: 5px;
    right: 5px;
  }

  &.pasado {
    background: $darker-gray;

    .btn-line.btn-help,
    .btn-line.btn-help:hover {
      color: white !important;
      border-color: white !important;
      margin-left: 0.5rem;
      //font-size: 1.5rem;
      display: inline-block;
    }
  }
}

.p-dialog-visible {

  z-index: 100006 !important;
}

.p-dialog .p-dialog-content {

  max-height: 90vh;
  overflow: visible;

  .p-grid {
    //overflow: auto;
    max-height: 100%;
  }

}

.p-dialog-content ul {

  padding-inline-start: 10px;
}


.p-button.invisible-button {
  background: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
  margin: auto !important;
  display: block;

  .p-button-text {
    display: none;
  }
}

.p-button.invisible-button2 {
  background: transparent !important;
  border-color: transparent !important;
  display: inherit;
  padding: 0;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;

  .p-button-text {
    display: none;
  }
}

.compras-detalles-text {
  font-size: 14px;
}
.thin-border-bottom{
  border-bottom: .5px solid black;
}

.dashed-border-bottom{
  border-bottom: 1px dotted black;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 14px;
}
.button-disabled{
  color: gray;
  border-color: gray !important;
  opacity: .7;
}

.text-small{
font-size: 9px;
}

.whi-sp{
  white-space:pre; /* or pre-wrap if you want wrapping to still work. */
}
.text-lighter{
  color: gray;
}

//Para template de dropdown de presentaciones en reporte de calidad general de pesos diarios
.unidad-kg-bold{
  .label-unidad{
    color:gray;
  }

}
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  .label-unidad{
    color:#ffffff;
  }
}
.disabled-check  .p-checkbox .p-checkbox-box .p-checkbox-icon{
    background-color: gray;
}

.ul-padding-inline1{
  padding-inline-start: 15px !important;
}