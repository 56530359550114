.icon-id{
  &.Deshabilitado,  &.deshabilitado{
  	background-color: $gray-superlight !important;
      box-shadow:none;
      text-shadow: none;
      @mixin colores2() {
        @each $color-name, $color in $colors {
          
          &.bor-#{$color-name} {
            color: darken($color,10) !important;
          }
          
        }
      }
      @include colores2();
  }
}

.htg-card{
	margin-top: 30px;
	position:relative;
	border-style: solid;
	border-width:2px;
	box-sizing: border-box;
	border-radius:10px;
	cursor: pointer;
  &:hover{
    //transform: translate(0,-2px);
    transition: all  0.3s;
    z-index: 100000;
  }
	p{
		margin: 0;
		
	}
  &.Deshabilitado,  &.deshabilitado{
    .card-id, &.p-card{

      background-color: $gray-superlight !important;
      box-shadow:none;
      text-shadow: none;
      @mixin colores2() {
        @each $color-name, $color in $colors {
          
          &.bor-#{$color-name} {
            color: darken($color,10) !important;
          }
          
        }
      }
      @include colores2();
    }
    .card-up, .card-down{
        color: $gray-dark;
      }
    .p-card-body{
      color: $gray-dark;
    }
  }
}

body{
  .p-card, .card{
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
    
  }
}

.p-card-body{
  padding:38px  14px 10px 14px ;
}

.p-card-body, .bio-data{
	.p-col{
		padding:0;
	}
	
  .label-hl{
    font-weight: bold;
    text-transform: uppercase;
  }
}

.card-block{
		padding: 0.25em 0.5em ;
    p{
      margin:0;
    }
    .value{
      font-size: 1.35em;
      white-space: nowrap;
    }
    .label{
      font-size:0.7em;
    }
	}
	
.bio-data{
  
  .value{
    font-size: 2.35em;
   
  }
}
.card-header{
	margin-top: -30px;
	//padding-top: 50px;
	position: absolute;
	display: flex;
	left: 17px;
	align-items: flex-end;
	p{
		line-height: 30px;
	}
	
}
.card-id{
	margin-right: 0.5em;
  text-shadow: 1px 2px 5px rgba(0,0,0,0.18);
  svg{
      -webkit-filter: drop-shadow(1px 2px 5px rgba(0,0,0,0.18));
  filter:drop-shadow(1px 2px 5px rgba(0,0,0,0.18))
  /* Similar syntax to box-shadow */
  }
  

}
.card-up{
  text-transform: uppercase;
}
.card-down{}


.grafica-card{
  .htg-card{
    margin-top: 10px;
  }
  .p-card-body{
    padding:12px 14px;
  }
  .p-card{
    background-color:#f4f6fa !important;
  }
  img{
    width: 100%;
    
  }
  .card-title{
    font-size: 1.1em;
    
    margin-bottom: 0.8em;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
}

.card-btns{
    display: flex;
    flex-wrap: wrap;
    

  button{
    flex: 1 0 auto;

  }
}

.title-card-inner{
  width: 100%;
  display: block;
  border-bottom:1px solid $gray;
  padding:1em 0 0.5em 0;
  

}

