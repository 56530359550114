$main-blue:#003E66;
$main-green:#44A026;
$main-red:#DB000E;
$main-orange:#F68600;

$green:#62D60A;
$green-dark:#44A026;
$blue:#45abec;//#0598F7;
$blue-dark:#047bda;//#00558D;
$pink:#FC66B7;
$pink-dark:#D60074;
$red:#FF3C3C;
$red-dark:#DB000E;
$purple:#BA3FEB;
$purple-dark:#7C08C4;
$orange:#FCAD5A;
$orange-dark:#E67E00;
$yellow:#FDE450;
$yellow-dark:#EBCA12;
$gray:#BAC3C3;
$gray-dark:#94A5A5;

$gray-superlight:#F4F6FA;
$gray-lighter:#eeeeee;
$gray-light:#e1e2e2;
$darker-gray:#707070;

$light-green:lighten(#62D60A,50);
$light-blue:#e5eef7;