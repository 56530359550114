.tabla-empleados,.tabla-nomina-totales{
  table-layout: unset;
   .p-column-title{
        
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          margin: auto;
          max-height:10em;
      }
    td:last-child, td:nth-last-child(2),
    th:last-child, th:nth-last-child(2){
      font-weight: bold;
      background-color: $light-blue !important;
    }
}
.tabla-empleados{
	//background:white;

  .novato{
    
      background: $light-green !important;
      td:last-child, td:nth-last-child(2),
      th:last-child, th:nth-last-child(2){
        
        background:rgba(20,150,150,0.1) !important;
      }
  }
  .novato-label{
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: bold;
  }
	.ausente{
		td{
      background:rgba(255,50,50,0.06) !important;
    }

    td:last-child, td:nth-last-child(2),
    th:last-child, th:nth-last-child(2){
      
      background:rgba(105,50,150,0.1) !important;
    }

		td:last-child{
			color: red !important;			
			&:after{
				content: " (Sin bono)";

				font-size: 0.7em;
				text-transform: uppercase;
			}
		}
	}
  
  .inactivo{  
    //background: $gray-superlight !important;
    color: $gray !important;
    color: rgba(0,0,0,0.5) !important;
    background: rgba(100,100,100,0.01) !important;

    td:first-child{
      &:after{
        content: " (Deshabilitado)";
        font-size: 0.7em;
        text-transform: uppercase;
      }
    }
    td:last-child, td:nth-last-child(2){
      
      background: rgba(50,100,200,0.1) !important;
    }
    td{border-color:black;}

  }
}

.ausencias-week{
	display: flex;
	span{
		display: block;
		width: 16px;
		height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		border:1px solid rgba($gray, 0.7);
		margin:0 1.5px;
		border-radius:4px;
		font-size: 0.6em;
		font-weight: 600;
		&.falta{
			background: $red;
			border-color: darken($red,5);
			color: white;
     
		}
		&.vacaciones{
			background:$blue;
			border-color:darken($blue,5);
			color: white;	
     	
		}
		&.permiso{
			background:darken($green,10);
			border-color:darken($green,15);
			color: white;		
     
		}
	}
}



		

$ausencias: (
  falta: red,
  vacacion: blue,
  permiso: green,
);

$dias: (
  lunes:1,
  martes:2,
  miercoles:3,
  jueves:4,
  viernes:5,
  sabado:6,
);

@mixin ausencias-mixin($property) {  
  @each $dia, $num in $dias {
    @each $name, $hex in $ausencias {
      .#{$dia}-#{$name} {
        &:nth-child(#{$num}){
          #{$property}: $hex;
          color:white;
        }
      }
    }
  }
}

@include ausencias-mixin(background);

.lista-notas{
  
  padding-left: 1.6em;
  li:first-child{
    margin-top:0.5em;
  }
  li:last-child{
    margin-bottom:0.5em;
  }
}


.excedido{
  color:red;
  font-weight: 600;
}
