.p-tabmenu-nav{
	border-bottom: none;
}
body .p-tabmenu .p-tabmenu-nav {
	border-bottom:none;
	.p-tabmenuitem{
		
		&.p-highlight{
			
			.p-menuitem-link{
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
				background: white !important;
				border-color:white !important;
				&:after{
					content: "";
					position: absolute;
					top:100%;
					left: 0;
					width: 100%;
					height: 10px;
					background:white;
				}
				.p-menuitem-text{
					color: #333333 !important;

				}
			}
		}
	
		.p-menuitem-link{
			border:none !important;
			padding: 0.871em 1em;
			border-top-left-radius: 13px;
    		border-top-right-radius: 13px;
			.p-menuitem-text{
				color: $main-blue !important;
				font-size: 1.1em;
			}
			background:transparent !important;
		}
	}

}
