$claro-width: 1.5em;
$margin-size: 0.5em;
$enfermedad-size: ($claro-width/6) - $margin-size * 2;
$claro-height: ($claro-width * 2);
$factorZoom: 10;

.tools {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.mapa-invernadero {
  border-collapse: collapse;
  //background:white;
  //font-size: 16px;
  width: 100%;
  tr,
  td {
    margin: 0;
    padding: 0;
  }
  transform: scale(0.1) translate(0px, 0px);
  transform-origin: left top;
}
.mapa-capilla {
  //border: 0.1px *  $factorZoom solid $gray;
  background: transparent;
  border-collapse: collapse;
}
.mapa-capilla-polo {
  padding: 0.5em;
  border-collapse: collapse;
  border: none;
  td {
    padding: 0;
    //border:1px solid red;
  }
}
.temp-letra {
  display: none;
  color: white;
  font-size: 4px;
}
.react-transform-component {
  // min-width: 100%;
  //display: block;
  border: 1px solid gray;
}
.num-col,
.num-fila {
  width: 3px * $factorZoom;
  height: 3px * $factorZoom;
  border: 0.1px solid $gray;
  color: $gray-dark;
  font-size: 3px * $factorZoom;
  padding: 3px * $factorZoom;
  //margin:16px * fact $autoorZoom;
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.num-col {
  margin: 3px * $factorZoom auto;
}
.num-fila {
  margin: auto 3px * $factorZoom;
}

.mapa-invernadero-claro {
  margin: 2px * $factorZoom 2px * $factorZoom;
  //background:rgba(0,0,0,0.1);
  display: block;
  padding: 0 !important;
  border: none;
  position: relative;
  box-shadow: 0 0 0 0.1px * $factorZoom gray;
  box-shadow: none;
  border: 1px solid gray;
  border: 1px solid transparent;
  width: 40px;
  height: 40px;
  border-radius:50%;
  background: rgba(255, 255, 255, 0.7);

  &[class*="pasada-prioridad-3"] {
    
    background: lighten(#b7daff, 2);
   
  }
 
}
div[class*="actual-prioridad-3"] {
  //background: #d8e7f5;//#e5eef7;
  box-shadow: 0 0 0 3px $main-blue;
  //border: 2px dotted #1778d8 !important;
}

div[class*="pasada-prioridad-3"] {
  //background: #e5eef7;
  //border: 2px dotted #1778d8 !important;
}
$enfermedades-1: (
  cáncer-bacteriano-clavibacter: ($red, 1 ),
  virus-del-rugoso: ($purple, 2 ),
  cenicilla-leveillula-t-erysiphe-spp: ($green-dark, 3 ),
  virus-del-mosaico: ($blue, 4 ),
  fitoplasma: ($green, 5 ),
  paratrioza-psilido: ($pink, 6 ),
  secadera-pudriciones-pythium-phytophthora-capsici-rhizoctonia-solani: ($blue-dark, 7 ),
  secadera-taponamientos-verticillium-spp-ralstonia-spp-fusarium-spp: ($orange, 8 ),
  varias:($yellow, 9 ),

);
$enfermedades-2: (
  acaro-bronceado: (s9),
  araña-roja: (s9),
  botrytis: (s9),
  crazy-roots-agrobaterium-spp: (s9),
  erwina: (s9),
  fungus-gnat-mosca-esciárida: (s9),
  gusanoorugas: (s9),
  mancha-foliar-alternaria-spp-stemphylium-colletotrichum-: (s9),
  phytopthora-infectans-tizon-tardío: (s9),
  pseudomonas-spp-corrugata: (s9),
  puntos-negros-en-fruto--alternaria-alternata-colletotrichum: (s9),
  thrips: (s9),
  virus-del-tabaco: (s9),
  virus-del-pepino: (s9),
  virus-del-bronceado-spotted: (s9),
  xanthomonas: (s9),
  fulvia: (s9),
  virus-desconocido: (s9)
);
@mixin enfermedades() {
  @each $nombre, $style in $enfermedades-1 {
    .actual.#{$nombre} {
      &:after {
        content: "";
        background: nth($style, 1);
        border-color: nth($style, 1);
      }
      & + span{
      font-weight: bold;  
      }
      
     
    }
    .pasada.#{$nombre} {
      &:after {
        content: "";
        background: lighten(nth($style, 1),
         30);
        border-color: nth($style, 1);
      }
    }
    .icon-list{
      &.actual.#{$nombre} {
          background: nth($style, 1);
          border-color: nth($style, 1);
      }
      &.pasada.#{$nombre} {
          background: nth($style, 1);
          border-color: nth($style, 1);
          
      }
    }
    div[class^="zoomLevel-3"],div[class^="zoomLevel-4"], div[class^="zoomLevel-5"],div[class^="zoomLevel-6"]  {
      table .pasada.#{$nombre} {
        &:after {
          width: 160px - (12  * nth($style, 2));
          height: 160px - (12 * nth($style, 2));
          border-width: 3px;
          border-style:solid;
          border-radius:50%;
        }
      }
      .icon-list{
        &.actual.#{$nombre} {
            border-radius:50%;
            display: inline-block;
        }
        &.pasada.#{$nombre} {
            background:transparent;
            border-radius: 50%;
            border-style:solid;
            border-width:1px;
            width: 20px;
            height: 20px;
        }
      }
      .varias{
        display: none;
      }
    }
    div[class^="zoomLevel-1"] , div[class^="zoomLevel-2"] {
        
      .icon-list{

        &.actual.#{$nombre} {
          border-radius:50%;
        }
        &.pasada.#{$nombre} {
          width: 20px;
          height: 20px;
            //background:transparent;
            border-radius: 50%;
            border-style:solid;
            opacity: 0.4;
            //border-width:1px;
        }
      }
    }
    
  }
  @each $nombre, $style in $enfermedades-2 {
    .actual.#{$nombre} {
      //background: $light-blue;
      display: none;
      &:after {
        content: "";
        background: transparent;
        border: 0;
        border-color: transparent;
      }
      //grid-area:  nth($style, 1);
    }
    .pasada{
      //border:none !important;
    }
    div[class^="zoomLevel-1"],div[class^="zoomLevel-2"]  {
      .icon-list{

        &.actual.#{$nombre} {
          border-radius:50%;

        }
        &.pasada.#{$nombre} {
          display: none;
        }
      }
    }
    div[class^="zoomLevel-3"],div[class^="zoomLevel-4"], div[class^="zoomLevel-5"],div[class^="zoomLevel-6"]  {
      
      .icon-list{
        &.actual.#{$nombre} {
            border: 1px solid #003E66;
            display: inline-block;
        }
        &.pasada.#{$nombre} {
                background: #c1dfff;
        }
      }
      
    }
  }
    
}
@include enfermedades();
.capilla-titles {
  text-align: left;
  display: block;
  margin: 1em;
  height: 120px;
  display: flex;
  justify-content: flex-end;
  align-items:flex-end;
}
.capilla-number-title {
  text-align: left;
  display: block;
  font-size: 1em;
}
.capilla-polo-title {
  text-align: left;
  display: block;
  font-size: 0.8;
  text-transform: uppercase;
}
@mixin zoomlevel() {
  @for $i from 1 through 3 {
    div[class^="zoomLevel-#{$i}"] table {
      .capilla-titles {
        //font-size:130px - (10px * ($i * 5);
        //display: none;
      }
    }
  }
}

.capilla-titles {
  margin: 90px 0px 30px 150px;
  span {
    text-align: center;
  }
}
div[class^="zoomLevel-2"] table,
div[class^="zoomLevel-1"] table {
  .mapa-invernadero-claro[class*="pasada-prioridad-3"] {
    &:after {
      content: none;
    }
  }
  .capilla-titles {
    font-size: 90px;
  }
  .num-fila,
  .num-col {
    opacity: 0;
  }
  .mapa-invernadero-claro {
    //box-shadow: 0 0 0 4px white !important;
    box-shadow: none;
    margin: 2px * $factorZoom;
    width: 4px * $factorZoom;
    height: 4px * $factorZoom;
    background: rgba(0, 0, 0, 0.15);
    .icon-enfermedad {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;

      //background: yellow;
      border: 0;
      position: absolute;
      &:after {
        display: block;
      }
    }
    .icon-enfermedad.actual {
      &:after {
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border-radius: 0;
        border-radius:50%;
        //background: yellow;
        border: 0;
      }
    }
    
  }
  .enfermedades-importantes-2,
  .enfermedades-importantes-3,
  .enfermedades-importantes-4,
  .enfermedades-importantes-5,
  .enfermedades-importantes-6,
  .enfermedades-importantes-7,
  .enfermedades-importantes-8 {
    .icon-enfermedad.actual {
      &:after {
        background: $yellow;
      }
    }
  }
  .enfermedades-pasadas-importantes-2,
  .enfermedades-pasadas-importantes-3,
  .enfermedades-pasadas-importantes-4,
  .enfermedades-pasadas-importantes-5,
  .enfermedades-pasadas-importantes-6,
  .enfermedades-pasadas-importantes-7,
  .enfermedades-pasadas-importantes-8 {
    .icon-enfermedad.pasada {
      &:after {
        background: lighten($yellow, 20);
      }
    }
  }
}
div[class^="zoomLevel-2"] table {
  .capilla-titles {
    font-size: 60px;
  }
}

div[class^="zoomLevel-3"],div[class^="zoomLevel-4"], div[class^="zoomLevel-5"],div[class^="zoomLevel-6"] {
  table {
    .capilla-titles {
      font-size: 45px;
    }
  }
  .mapa-invernadero-claro {
    display: flex;
    vertical-align: middle;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    height: 80px;
    margin: 0 20px;
    border-radius:0;
    flex-flow: row wrap;
    border: 1px solid #f0f0f0 ;
  }
  .icon-enfermedad.actual {
    width: 20px;
    height: 20px;
    margin: 0;
    margin-bottom: -2%;
    padding-bottom: 0;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: 50%;
    &:after {
      width: $enfermedad-size;
      height: $enfermedad-size;
      width: 80%;

      margin: $margin-size;
      margin: 10%;

      height: 0;
      padding-bottom: 80%;
      //padding-bottom:0;
      display: block;
      box-sizing: content-box;
      border-radius: 50%;
      /*
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        */
    }
    &.enfermedad {
    }
    
    
    /*
      &.pasada{
      &:after{
      border-width:2px ;
      border-style:solid;
      width: 90%;
      padding-bottom:90%;
      background:transparent;
      box-sizing:content-box;
      }
      }
      */
  }
  .icon-enfermedad{
    &.pasada{
      position:absolute;
      top: 50%;
      left: 50%;
      
      
      &:after{
        top:0;
        left: 0;
        background:transparent !important;
        
      }
    }
  }
}
.icon-enfermedad.pasada {
  &:after {
    content: "";
    width: 14px * $factorZoom;
    height: 14px * $factorZoom;
    border-radius: 50%;
    //background:rgba(255,0,0,0.2);
    //background:#ffd2d8;
    display: block;
    position: absolute;
    top: 50%;
    top: 20px;
    left: 50%;
    left: 20px;
    transform: translate(-50%, -50%);
    z-index: -3;
  }
}

.enfermedades-item{
  list-style: none;
  font-size: 0.8em;
  .icon-enfermedad{
    width: 10px;
  }
  
    margin-bottom: 0.5em;
  
}
.p-dialog-content ul{
  margin-bottom: 1em;
}
.icon-list{
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right:5px;
}

.modal-subtitle{
  margin: 0 0 0.5em 0;
  font-weight: bold;
  display:block;
}


.mapa-invernadero{
        .mapa-invernadero-claro{
          &.nivel-incidencia-pasado-1,&.nivel-incidencia-pasado-2,&.nivel-incidencia-pasado-3,&.nivel-incidencia-pasado-4{
            &:before {
              content: "";
              width: 140px;
              height: 140px;
              border-radius: 50%;
              display: block;
              position: absolute;
              
              top: 20px;
              
              left: 20px;
              transform: translate(-50%, -50%);
              z-index: -1;
              mix-blend-mode: multiply;
            }
          }

          &.nivel-incidencia-cal-pasado-1,&.nivel-incidencia-cal-pasado-2,&.nivel-incidencia-cal-pasado-3,&.nivel-incidencia-cal-pasado-4,&.nivel-incidencia-cal-pasado-5{
            &:before {
              content: "";
              width: 140px;
              height: 140px;
              border-radius: 50%;
              display: block;
              position: absolute;
              
              top: 20px;
              
              left: 20px;
              transform: translate(-50%, -50%);
              z-index: -1;
              mix-blend-mode: multiply;
            }
          }
        }
  &.enfermedad-seleccionada-prioridad-importante{
    .mapa-invernadero-claro{
      &.nivel-incidencia-actual-1,
      &.nivel-incidencia-actual-2,
      &.nivel-incidencia-actual-3,
      &.nivel-incidencia-actual-4 {
        background: #FF3C3C !important;
      }
      &.nivel-incidencia-pasado-1,
      &.nivel-incidencia-pasado-2,
      &.nivel-incidencia-pasado-3,
      &.nivel-incidencia-pasado-4 {
        &:before{background: lighten( #FF3C3C,30);}
      }


      &.nivel-incidencia-cal-actual-1{
        background: #2241a8 !important;
      }
      &.nivel-incidencia-cal-actual-2{
        background: #44A026 !important;
      }
      &.nivel-incidencia-cal-actual-3{
        background: #d8b90d !important;
      }
      &.nivel-incidencia-cal-actual-4{
        background: #E67E00 !important;
      }
      &.nivel-incidencia-cal-actual-5 {
        background: #FF3C3C !important;
      }
     
     
     
     
      &.nivel-incidencia-cal-pasado-1{
        background: #2241a8 !important;
      }
      &.nivel-incidencia-cal-pasado-2{
        background: #44A026 !important;
      }
      &.nivel-incidencia-cal-pasado-3{
        background: #d8b90d !important;
      }
      &.nivel-incidencia-cal-pasado-4{
        background: #E67E00 !important;
      }
      &.nivel-incidencia-cal-pasado-5 {
        background: #FF3C3C !important;
      }
    }
  }

  &.enfermedad-seleccionada-prioridad-normal{
    .mapa-invernadero-claro{
      &.nivel-incidencia-actual-1 {
          background: #44A026 ;
          
      }
      &.nivel-incidencia-actual-2 {
          background: #d8b90d ;
          
      }
      &.nivel-incidencia-actual-3 {
          background: #E67E00 ;
          
      }
      &.nivel-incidencia-actual-4 {
          background: #FF3C3C ;
          
      }
      &.nivel-incidencia-pasado-1 {
          
          &:before{background: lighten( #44A026,30);}
      }
      &.nivel-incidencia-pasado-2 {
          
          &:before{background: lighten( #d8b90d,30);}
      }
      &.nivel-incidencia-pasado-3 {
          
          &:before{background: lighten( #E67E00,30);}
      }
      &.nivel-incidencia-pasado-4 {
          
          &:before{background: lighten( #FF3C3C,30);}
      }



    
    &.nivel-incidencia-cal-actual-1 {
        background: #2241a8 ;
    }
    &.nivel-incidencia-cal-actual-2 {
        background: #44A026 ;
    }
    &.nivel-incidencia-cal-actual-3 {
        background: #d8b90d ;
    }
    &.nivel-incidencia-cal-actual-4 {
        background: #E67E00 ;
    }
    &.nivel-incidencia-cal-actual-5 {
      background: #FF3C3C ;
    }
    &.nivel-incidencia-cal-pasado-1 {
        
        &:before{background: lighten( #2241a8,30);}
    }
    &.nivel-incidencia-cal-pasado-2 {
        
        &:before{background: lighten( #44A026,30);}
    }
    &.nivel-incidencia-cal-pasado-3 {
        
        &:before{background: lighten( #d8b90d,30);}
    }
    &.nivel-incidencia-cal-pasado-4 {
        
        &:before{background: lighten( #E67E00,30);}
    }
    &.nivel-incidencia-cal-pasado-5 {
        
      &:before{background: lighten( #FF3C3C,30);}
  }
          
    }
  }
}



.enfermedad-name{
  font-weight: bold;
}

.icon-incidencia{
      display: inline-block;
    width: 10px;
    height: 10px;
    vertical-align: middle;
    margin-bottom: 2px;

    margin: 0 0.4em 2px 0.4em;
    border-radius: 50%;
    &.nivel-incidencia-1 {
      background: #44A026 !important;
      
    }
    &.nivel-incidencia-2 {
        background: #d8b90d !important;
        
    }
    &.nivel-incidencia-3 {
        background: #E67E00 !important;
        
    }
    &.nivel-incidencia-4,&.prioridad-importante{
        background: #FF3C3C !important;
    }


    
    &.nivel-incidencia-cal-1 {
      background: #2241a8 !important;
    }
    &.nivel-incidencia-cal-2 {
        background: #44A026 !important;
    }
    &.nivel-incidencia-cal-3 {
        background: #d8b90d !important;
    }
    &.nivel-incidencia-cal-4 {
      background: #E67E00 !important;
    }
    &.nivel-incidencia-cal-5,&.prioridad-importante{
        background: #FF3C3C !important;
    }
}

.oa-mapa-invernadero{
  width: 100%;
  overflow: auto;
  &.seleccion-claro{
    
    .oa-claro{
        &:hover{
          
          &:after{
            
            opacity: 0.2;
          }
        
        }
      
    }
  }
  &.seleccion-linea{
    .oa-linea{
      &:hover{
        .oa-claro{
          
          &:after{
            
            opacity: 0.2;
          
          }
        }
      }
    }
  }
  &.seleccion-capilla{
    .oa-capilla{
      &:hover{
        .oa-claro{
          
          &:after{
            
            opacity: 0.2;
          
          }
        }
      }
    }
  }
  &.seleccion-polo{
    .oa-polo{
      &:hover{
        .oa-claro{
          
          &:after{
            
            opacity: 0.2;
          }
          
        }
      }
    }
  }
}


.oa-polo{
  display: flex;
  font-size:0.7em;
  text-align: center;
  margin:10px 0;
}
.oa-capilla{
  
  display: flex;
  padding:4px;
}
.oa-linea-numero{
  .oa-claro{
    padding:0;
    line-height: 0.9;
    &:after{
       content: none;
    }
    
  }
}
.oa-linea, .oa-linea-numero{
  //margin:1%;
  font-size: 0.6em;
  line-height: 1;
}
.oa-claro{
  width: 10px;
  height: 10px;
  
}
.oa-claro{
  width: 10px;
  height: 10px;
  padding: 2px;
  position:relative;
  //background: rgba(255, 255, 255, 0.7);
  text-align:center;
  &.seleccionado{
    
      background:rgba($green,0.4);
    
  }
    &:after{
      content: "";
      
      width: 100%;
      height: 100%;
      position:absolute;
      top:0;
      left: 0;
      background:blue;
      opacity: 0;
    } 
  &.oa-con-incidencia{
    .oa-claro-interior{
      background:$red;
      border-color:darken($red, 10);
      
    }
  }
  .oa-claro-interior{
    position:relative;
    
    width: 100%;
    height: 100%;
    border-radius:50%;
    border:0.5px solid $gray;
    
  }
}

.oa-titulo-capilla,.oa-titulo-linea{
  line-height: 1.1;
}
.firma-aplicador{
  display: none;
}
.firma-grower{
  display: none;
}





@media print {
  .show-print{
    display: block;
  }
  .layout-main{
    padding:0;
  }
  .layout-topbar,
  .back-btn,
  .no-print{
    display: none;
  }  

  .oa-mapa-invernadero{
    width: 100%;
    //overflow: visible;
    //transform: rotate(90deg);
  }

.oa-polo{
  display: flex;
  width: 100%;
  font-size:0.6em;
  text-align: center;
  margin:10px 0;
  display: flex;
   // flex-flow: row wrap;
   .oa-capilla-container:first-child{
    .oa-linea-numero{
      //display: block !important;
    }
   }
}
.oa-capilla-container{
  width: 100%;

}
.oa-capilla{
  width: 100%;
  display: flex;
  padding:1px 2px 1px 1px;
  border-right:0.5px solid gray;
  align-items: center;
}
.oa-linea-numero{
   display: flex;
  flex-flow:column;
  width: 100%;
  box-shadow: none;
  .oa-claro{
    padding:0;

    
    &:after{
       content: none;
    }
    
  }
}
.oa-linea{
  display: flex;
  flex-flow:column;
  width: 100%;
  align-items: center;
}
.oa-linea, .oa-linea-numero{
  //margin:1%;
  font-size: 0.6em;
  line-height: 1;
}
.oa-linea-numero{
  display: none;

}
.oa-claro{
  width: 3px;
  width: 70%;
  margin:auto;
  height: 6px;
  padding: 0px;
  margin:2px 0px;
  position:relative;
  //background: rgba(255, 255, 255, 0.7);
  text-align:center;
  box-shadow:inset 0px 0px 0px 1px rgba(150,150,150,0.5);
  &.seleccionado{
    
      //background:rgba($green,0.4);
      box-shadow:inset 0px 0px 0px 10px rgba(100,100,100,1);
    
  }
    &:after{
      content: "";
      content: none;
      
      width: 100%;
      height: 100%;
      position:absolute;
      top:0;
      left: 0;
      background:blue;
      opacity: 0;
    } 
  &.oa-con-incidencia{
    .oa-claro-interior{
      background:$red;
      border-color:darken($red, 10);
      
    }
  }
  .oa-claro-interior{
    position:relative;
    
    width: 100%;
    height: 100%;
    border-radius:50%;
    border:0.5px solid $gray;
    display: none;
    
  }
}

.oa-titulo-capilla,.oa-titulo-linea{
  line-height: 1.1;
}



  .oa-mapa-invernadero{
    width: 100%;
    overflow: visible;
    
  }

}
//estilos impresion
  


.enfermedades-orden{
  padding: 5px 0; 
  display: inline-block;

  &:after{
    content: ", ";
  }
  &:last-child(){
    padding-right:10px;
    &:after{
      content: " ";
    }
  }
  &:first-child(){
    padding-left:10px;
  }
}
.linea-firma{
  width: 200px;
  border-bottom: 2px solid #000;
}
.text-aplicador{
  font-size: 1.2em;
  margin-top: 20px;
}
.linea-firma-aplicador{
  margin-top: 95px;
  width: 200px;
  border-bottom: 2px solid #000;
}
