$colors: (
  white: white,
  green: $green,
  green-dark: $green-dark,
  blue: $blue,
  blue-dark: $blue-dark,
  pink: $pink,
  pink-dark: $pink-dark,
  red: $red,
  red-dark: $red-dark,
  purple: $purple,
  purple-dark: $purple-dark,
  orange: $orange,
  orange-dark: $orange-dark,
  yellow: $yellow,
  yellow-dark: $yellow-dark,
  gray: $gray,
  gray-dark: $gray-dark,
  main-blue: $main-blue,
  main-green: $main-green,
  main-red: $main-red,
  main-orange: $main-orange,
  gray-superlight: $gray-superlight,
  gray-lighter: $gray-lighter,
  gray-light: $gray-light,
  darker-gray: $darker-gray,
  transparent: transparent,

);


@mixin colores() {
  @each $color-name, $color in $colors {
    .bg-#{$color-name} {
      background: $color;
      background-color: $color !important;
      color: white ;
    }
    .bg-ccc {
      background: yellow;
      background-color: yellow !important;
      color: yellow ;
    }
    .bor-#{$color-name} {
      border-color: darken($color,5) !important;
    }
    .color-#{$color-name} {
      color: $color;
    }
  }
}

@include colores();

