

$colorsRgb: (
  
  green: 'rgb(98, 214, 10)',
  green-dark: 'rgb(68, 160, 38)',
  blue: 'rgb(69, 171, 236)',
  blue-dark: 'rgb(4, 123, 218)',
  pink: 'rgb(252, 102, 183)',
  pink-dark: 'rgb(214, 0, 116)',
  red: 'rgb(255, 60, 60)',
  red-dark: 'rgb(219, 0, 14)',
  purple: 'rgb(186, 63, 235)',
  purple-dark: 'rgb(124, 8, 196)',
  orange: 'rgb(252, 173, 90)',
  orange-dark: 'rgb(230, 126, 0)',
  yellow: 'rgb(253, 228, 80)',
  yellow-dark: 'rgb(235, 202, 18)',
  gray: 'rgb(186, 195, 195)',
  gray-dark: 'rgb(148, 165, 165)',
  
);



@mixin LineasColoresRgb() {
  .cosecha-chart{
    @each $color-name, $color in $colorsRgb {
      
      
      span[style*="#{$color}"]{

        @extend .span-lineas;


        & ~ span, & ~ div{
          .dash-type{
            .solid-line{
              background-color: #{$color};
            }
            .dashed-line,.point-line{
              background-image: linear-gradient(90deg, transparent, transparent 50%, #fff 50%, #fff 100%), linear-gradient(90deg, #{$color}, #{$color});
            }
          }
        }
      }
    }
  }
}

@include LineasColoresRgb();


  .span-lineas{
  display:none;
  & ~ span, & ~ div{
    .dash-type{
      span{
        width: 30px;
        margin-bottom: 3px;
        display: inline-block;
        height: 3px;
      }
      .solid-line{
        border: none;
      }
      .dashed-line{
        background-size: 10px 3px, 100% 3px;
        border: none;
      }
      .point-line{
        background-size: 2px 3px, 100% 3px;
        border: none;
      }
    }
  }
}
