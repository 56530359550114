.p-dialog.spinner{    
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    //background: rgba(240,240,240,0.2);
    transition: all .2s;
    .css-fxjkfb {
	 
	    border-width:  3px ;

	}
    .p-dialog-titlebar{
        display: none;
    }
    .p-dialog-content{
        background:transparent;
        border:none;
        overflow: visible;
    }
}


    