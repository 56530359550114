.title-container{
	display: flex;
	align-items: center;

}
.titles-group{
	display: block;
	margin-left: 1em;
}
.back-btn{
  width: 20px;
  height: 20px;
  border-radius:50%;
  border: 2px solid $main-blue;
  
  margin-right: 1em;
  margin-top:0.2em;
  cursor: pointer;
  position: relative;
  &:after{
    content: "";
    border-bottom:  1px solid $main-blue;
    border-left:  1px solid $main-blue;
    display: block;
    width: 6px;
    height: 6px;
    transform:  translate(-40%, -50%) rotate(45deg),;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
.icon-general{
	width: 60px;
	height: 60px;
	border-width: 2px;
  border-style: solid; 
  border-color: #A6A6A6;
	border-radius: 11px;


  display: flex;
  justify-content: center;
  align-items:center;
  box-sizing: border-box !important;
  background-position:center;
  background-repeat:no-repeat;
  &.icon-lort{
    img{
      max-width:40px;
    }
  }
}
.icon-image{
  background-size:contain;
}
.user-icon{
  background-image:url('../images/icons/user-icon.svg');
}

.product-icon{
  background-image:url('../images/icons/tomato-icon.svg');
}

.truck-icon{
  background-image:url('../images/icons/truck-icon.svg');
}

.quality-icon{
  background-image:url('../images/icons/quality-icon.svg');
}
.plant-icon{
  background-image:url('../images/icons/plant-icon.svg');
}
.boxes-icon{
  background-image:url('../images/icons/boxes-icon.svg');
}
.inv-icon{
  background-image:url('../images/icons/greenhouse-icon.svg');
}
.activity-icon{
  background-image:url('../images/icons/tools-icon.svg');
}
.presentacion-icon{
  background-image:url('../images/icons/tomatos-icon.svg');
}
.ciclo-icon{
  background-image:url('../images/icons/cycle-icon.svg');
}
.medico-icon{
  background-image:url('../images/icons/medical-icon.svg');
}
.bio-icon{
  background-image:url('../images/icons/bio-icon.svg');
}
.cumplimiento-icon{
  background-image:url('../images/icons/cumplimiento-icon.svg');
}
.chart-icon{
  background-image:url('../images/icons/chart-icon.svg');
  &[class*="bg-"]{

    background-image:url('../images/icons/chart-icon-white.svg');
  }
}
.compras-icon{
  background-image:url('../images/icons/shop-icon.svg');
}
.dashboard-icon{
  background-image:url('../images/icons/dashboard-icon.svg');
}
.external-icon{
  background-image:url('../images/icons/external-icon-2.svg');
}
.reporte-empaque-icon{
  background-image:url('../images/icons/boxes-check-icon.svg');
}
.costos-icon{
  background-image:url('../images/icons/costos-icon.svg');
}
.mexico-icon{
  background-image:url('../images/icons/mexico-icon.svg');
}
.it-icon{
  background-image:url('../images/icons/it-icon.svg');
}
.mtto-icon{
  background-image:url('../images/icons/mtto-icon.svg');
}



.icon-id{
    
    font-size: 1.1em;
    font-weight:bold;
  }
  .htg-card--fisico .icon-id{
    
    font-size: 1em;
    
  }
  .icon-mini{
    font-size: 0.8em;
    width: 30px;
  height: 30px;
  
border-radius: 5px;
  }

.header-title{ 
	margin: 0;
}
.header-subtitle{ 
	margin: 0;
	font-size: 0.8em;
	text-transform: uppercase;
}
.header-subtitle-bigger{ 
	margin: 0;
	font-size: 1em !important;
	text-transform: uppercase;
}

.section-title{
	font-size: 1.4em;
	margin: 0 0 0.5em 0;
}

.icon-inv-dropdown-container{
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
}
.icon-inv-sm{



  height: 32px !important;
  width: 32px !important;
  border-radius:  6px !important;
  font-size: 16px !important;
  margin: 0px !important;
  padding: 0px !important;
}
